import {PayPeriodType, PayType} from "@/constants/TarifficationConstants";
import {ResidencyStatus} from "@/constants/PersonConstants";

export default class CreateRERPayload {

    companyId: number | null = null;

    toBeFilledByCustomer = false;

    employees: Array<CreateREREmployeePayload> = [];

    constructor(init? : Partial<CreateRERPayload>) {
        Object.assign(this, init);
    }
}

export class CreateREREmployeePayload {

    employeeId: number | null = null;
    name: string | null = null;
    phone: string | null = null;
    email: string | null = null;
    actualizationOnly = false;
    workPlacesId: number[] = [];
    payPeriodType: PayPeriodType | null = null;
    payType: PayType | null = null;
    wageRate!: number;
    isRemote!: boolean;
    employmentStart!: Date;
    branchesId: number[] = [];

    // if it has already been registered
    ssnRequired = true;
    stateIdRequired = true;
    eadRequired = true;
    greenCardRequired = true;
    citizenPassportRequired = true;
    bankDetailsRequired = true;
    residencyStatus?: ResidencyStatus;

    constructor(init?: Partial<CreateREREmployeePayload>) {
        Object.assign(this, init);
    }

}

